const swiperEl = document.querySelector('[data-swiper]');
const params = {
  // array with CSS styles
  injectStyles: [`
				.swiper-button-prev,
				.swiper-button-next {
					display: none;
				}
				@media(min-width: 1024px) {
					.swiper-button-prev,
					.swiper-button-next {
						display: block;
					}
				}
				.swiper-button-prev {
					left: 1rem;
				}
				.swiper-button-next {
					right: 1rem;
				}
				.swiper-button-next, .swiper-button-prev {
					height: 1.5rem;
					width: 1.5rem;
					color: #fff;
					opacity: 50%;
					filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
				}
				.swiper-button-next:after, .swiper-button-prev:after {
					font-size: 1.5rem;
				}
				.swiper-button-next:hover, .swiper-button-prev:hover {
					opacity: 100%;
				}
				.swiper-pagination-bullet-active {
					background-color: #f39a1e;
				}
			`],
};
Object.assign(swiperEl, params);
swiperEl.initialize();
